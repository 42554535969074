@use 'sass:map';

/* Main colors most widely used across all screens and components.*/
$fundos-primary: (
  50: #f8ddee,
  100: #deb7d0,
  200: #c887b0,
  300: #9C5080,
  400: #a23279,
  500: #910e61,
  600: #7A1A53,
  700: #7e0a4f,
  800: #740845,
  900: #620433,
  A100: #ff92c2,
  A200: #ff5fa5,
  A400: #ff2c89,
  A700: #ff137a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

/* Also known as the secondary color. Used for floating action buttons and interactive elements. */
$fundos-accent: (
  50: #e8eefb,
  100: #c5d5f5,
  200: #9fb9ef,
  300: #799ce8,
  400: #5c87e3,
  500: #3f72de,
  600: #396ada,
  700: #315fd5,
  800: #2955d1,
  900: #1b42c8,
  A100: #fdfeff,
  A200: #cad5ff,
  A400: #97acff,
  A700: #7e97ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

/* Colors to convey error state. */
$fundos-warn: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$grey-palette: (
  50: #fafafa,
  100: #ebebeb,
  200: #eeeeee,
  300: #050404,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #7A7979,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white
  )
);

/* Colors used for element backgrounds. */
$fundos-background: (
  status-bar: map.get($grey-palette, 300),
  app-bar: map.get($grey-palette, 100),
  background: map.get($grey-palette, 50),
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: rgba(black, 0.12),
  selected-button: map.get($grey-palette, 300),
  selected-disabled-button: map.get($grey-palette, 400),
  disabled-button-toggle: map.get($grey-palette, 200),
  unselected-chip: map.get($grey-palette, 300),
  disabled-list-option: map.get($grey-palette, 200),
  tooltip: map.get($grey-palette, 700)
);

/* Used for text and icons. */
$fundos-foreground: (
  base: black,
  divider: #767575, // rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.26),
  disabled-text: map.get($grey-palette, 700),
  elevation: black,
  hint-text: map.get($grey-palette, 600),
  secondary-text: white,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: black,
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38)
);
