@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .general-rate {
    &__info {
      &--subtitle {
        &.grey {
          color: #767575;
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .general-rate {
    &__info {
      &--title {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.4rem;
      }
  
      &--subtitle {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
