@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .footer {
    &__link-item {
      color: mat.get-color-from-palette($foreground-palette, 'disabled-text');
  
      &--small {
        color: mat.get-color-from-palette($foreground-palette, 'disabled-text');
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .footer {
    &__link-item {      
      @include mat.typography-level($typography-config, 'subheading-1');
  
      &--small {
        font-weight: 400;
        font-size: 9px;
        line-height: 15px;
        letter-spacing: 0.2px;
      }
    }
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
