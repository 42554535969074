@mixin flexbox-align($align: center, $justify: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin inline-flexbox-align($align: center, $justify: center, $direction: row) {
  display: inline-flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
