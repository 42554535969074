@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .calendar-box {
    background: mat.get-color-from-palette($background-palette, card);
    border: 1px solid  mat.get-color-from-palette($primary-palette, 300);

    &__month {
      color: mat.get-color-from-palette($primary-palette, 600);
      background: mat.get-color-from-palette($primary-palette, 50);
    }

    &__day {
      color: mat.get-color-from-palette($primary-palette, 600);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .calendar-box {
    &__month {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 1.13px;
    }

    &__day {
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 1.13px;
    }
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
