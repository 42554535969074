@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .event-card {
    background-color: transparent;

    &__content {
      background-color: mat.get-color-from-palette($foreground-palette, base);
      color: mat.get-color-from-palette($foreground-palette, secondary-text);

      &.lighted {
        background-color: mat.get-color-from-palette($foreground-palette, secondary-text);
        color: mat.get-color-from-palette($foreground-palette, base);
      }
      &.home {
        background-color: #1D252C;
      }
    }

    &__footer {
      background-color: rgba(255 255 255 / 10%) !important;
      color:  mat.get-color-from-palette($foreground-palette, secondary-text);
    }
      &.home {
        background-color: #1D252C;
      }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .event-card {
    &__content {
      &--title {
        font-size: 1.25rem;
        font-weight: 700;
      }
  
      &--subtitle {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    &__footer {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
