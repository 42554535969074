@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .popover-location {
    border: 1px solid mat.get-color-from-palette($primary-palette, 50);

    &__box {
      button {
        &:active {
          background-color:mat.get-color-from-palette($primary-palette, 50);
          color: mat.get-color-from-palette($primary-palette, 500);
        }
      }
    }

    ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
      border: 1px solid mat.get-color-from-palette($primary-palette, 50);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .popover-location {

    /* stylelint-disable-line rule */
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
