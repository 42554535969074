@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .category-card {
    background: mat.get-color-from-palette($primary-palette, 600);

    &__title {
      color:  mat.get-color-from-palette($background-palette, 'card');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .category-card {
    &__title {
      @include mat.typography-level($typography-config, 'body-1');
    }
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
