@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');

  .search-input {
    &.mat-form-field {
      background-color: mat.get-color-from-palette($foreground-palette, secondary-text);
      color: mat.get-color-from-palette($foreground-palette, base);
    }

    &__card {
      small {
        color: mat.get-color-from-palette($foreground-palette, hint-text);

        &.strong {
          color: mat.get-color-from-palette($foreground-palette, base);
        }
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .search-input {
    &__card {
      &--title {
        @include mat.typography-level($typography-config, 'subheading-1');
      }
  
      &--subtitle {
        small {
          @include mat.typography-level($typography-config, 'caption');
        }
      }
    }
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
