@use '@angular/material' as mat;

/* ANGULAR MATERIAL DEFINITION
@function define-typography-level(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: normal)
*/

$fundos-typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $display-4: mat.define-typography-level(112px, 112px, 900, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(56px, 56px, 800, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(45px, 48px, 700, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(34px, 40px, 600),
  $headline: mat.define-typography-level(48px, 54px, 700, 'Raleway, sans-serif', -0.0037rem),
  $title: mat.define-typography-level(32px, 40px, 700, 'Raleway, sans-serif', -0.08px),
  $subheading-2: mat.define-typography-level(20px, 24px, 700, 'Raleway, sans-serif', 0.0081rem),
  $subheading-1: mat.define-typography-level(16px, 24px, 600, 'Raleway, sans-serif', 0.002rem),
  $body-2: mat.define-typography-level(18px, 28px, 700),
  $body-1: mat.define-typography-level(16px, 26px, 600),
  $caption: mat.define-typography-level(12px, 16px, 600),
  $button: mat.define-typography-level(15px, 20px, 600)
);
