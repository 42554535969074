@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .main-container {
    color: mat.get-color-from-palette($foreground-palette, secondary-text);

    &__btn {
      background-color: mat.get-color-from-palette($foreground-palette, base);
      color: mat.get-color-from-palette($background-palette, card);

      &--mobile {
        background-color: mat.get-color-from-palette($background-palette, app-bar) !important;
        color: mat.get-color-from-palette($foreground-palette, base) !important;
      }
    }

    &__user {
      &--default {
        background-color: mat.get-color-from-palette($primary-palette, 50);
        color: mat.get-color-from-palette($primary-palette, 500);
      }
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .main-container {
    &__btn, &__link {
      &--icon {
        line-height: 1.25rem;
      }
    }

    &__user {
      &--default {
        @include mat.typography-level($typography-config, 'body-1');
      }
    }
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
