@import 'helpers/mixins';
@import 'helpers/variables';

.custom-dialog {
  @include flexbox-align(center, center, column);
}

@include respond-to('medium') {
  .custom-dialog {

    /* stylelint-disable-line rule */
  }
}

@include respond-to('small') {
  .custom-dialog {
    width: 100%!important;
    height: 100%;
    max-width: 100%!important;
  }
}