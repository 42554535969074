.btn {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #181818;
}

.btn-chip {
  border: 1px solid #DDDDDB !important;
  border-radius: 22px !important;
  padding: 6px 16px !important;
  gap: 10px;
}

.btn-grey {
  background-color: #767575;
  color: white;
}

.btn-black {
  background-color: #000000;
  color: white
}