.full-screen {
  height: 100vh;
  width: 100vw;
}

.full-height {
  height: 100vh;
}

.ellipsis {
  // Element need a fixed width to work
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  border: 2px solid #fff;
  width: 100%;
  margin: 1rem 0 !important;
  box-sizing: border-box;

  &.dark {
    border: 1px solid #ebebeb;
  }
}
