@use 'SASS:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .dialog {
    background-color: mat.get-color-from-palette($background-palette, card);

    &__close {
      background-color: mat.get-color-from-palette($background-palette, card) !important;
      color: mat.get-color-from-palette($foreground-palette, divider) !important;
      border-color: mat.get-color-from-palette($foreground-palette, divider);
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
  $my-font-family: mat.font-family($typography-config);

  .dialog {

    /* stylelint-disable-line rule */
  }
}

@mixin theme($theme, $typography-config) {
  $color-config: mat.get-color-config($theme);
  @if $color-config {
    @include color($theme);
  }

  @if $typography-config {
    @include typography($typography-config);
  }
}
